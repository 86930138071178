import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from "../_services/user.service";

@Pipe({
  name: 'userPropertyAsync'
})
export class UserPropertyAsyncPipe implements PipeTransform {
  constructor(
    private userService: UserService,
  ) {
  }

  async transform(uid: string, path: string):Promise<any>  {
    return await this.userService.getUserProperty(uid, path);
  }

}
