import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Event, NavigationStart, Router, Route, CanLoad } from '@angular/router';
import { AuthService } from "../_services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const res = await this.authService.isConnected();
    if (!res) {
      if(this.router.url === '/') {
        this.router.navigate(['login']).then();
      } else {
        this.router.navigate(['login'], {queryParams: {returnUrl: state.url}}).then();
      }
    }
    return res;
  }

  async canLoad(route: Route): Promise<boolean> {
    let user = await this.authService.getUser();
    if (user && user.admin) return true;
    this.router.navigateByUrl("/not-found");
    return false;
  }

}
