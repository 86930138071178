import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerFormatPipe } from './timer-format';
import { ScreenSizePipe } from './screen-size.pipe';
import { SortPipe } from './sort.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { NumberToArrayPipe } from './number-to-array.pipe';
import { EnabledModulePipe } from './enabled-module.pipe';
import { DictionaryPipe } from './dictionary.pipe';
import { SimulationProgressEnabledPipe } from './simulation-progress-enabled.pipe';
import { AvatarSimulationProgressPipe } from './avatar-simulation-progress.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UserPropertyAsyncPipe } from './userPropertyAsync.pipe';

@NgModule({
  declarations: [
    PipesModule.PIPES,
    UserPropertyAsyncPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PipesModule.PIPES,
    UserPropertyAsyncPipe,
  ],
  providers: [
    PipesModule.PIPES
  ]
})

export class PipesModule {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static PIPES = [
    TimerFormatPipe,
    ScreenSizePipe,
    SortPipe,
    SafeHtmlPipe,
    NumberToArrayPipe,
    EnabledModulePipe,
    DictionaryPipe,
    SimulationProgressEnabledPipe,
    AvatarSimulationProgressPipe,
    TruncatePipe
  ];
}
