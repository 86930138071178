import { Pipe, PipeTransform } from '@angular/core';

declare type ScreenSizeType = 'xs' | 'lg';

@Pipe({
  name: 'screenSize'
})
export class ScreenSizePipe implements PipeTransform {
  transform(screenSize: number): ScreenSizeType {
    if (screenSize < 600) {
      return 'xs';
    }
    return 'lg';
  }

}
