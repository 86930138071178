import { Pipe, PipeTransform } from '@angular/core';
import { UserGradeEnum } from '../_utils/enums/user-grade.enum';
import { CategorySimulationType } from '../_utils/custom-types';
import { SIMULATION_PROGRESS_SETTINGS } from '../_utils/settings/simulation-progress.settings';
import { ModuleEnum } from '../_utils/unums/module.enum';
import { ComponentBase } from '../_utils/component.base';

@Pipe({
  name: 'simulationProgressEnabled'
})
export class SimulationProgressEnabledPipe extends ComponentBase implements PipeTransform {
  constructor() {
    super();
  }

  async transform(simulationLevel: number, simulations: any[], module: ModuleEnum, category: CategorySimulationType, simulationProgressSettings: any): Promise<boolean> {
    if (!simulationLevel) {
      return true;
    }
    if (!module || !category) {
      return false;
    }
    if (simulationLevel === 1) {
      return true;
    }
    const settings = simulationProgressSettings;
    const userGrade: UserGradeEnum = await this.userJourneyService.getUserGrade(module);
    const userSettings = settings[category].find(x => x.userGrade === userGrade);
    let enabledSimulation = true;
    for (const levelSettings of userSettings.config) {
      if (levelSettings.level >= simulationLevel) {
        break;
      }
      const completedSimulations = simulations.filter(x => x.completed && x.level === levelSettings.level);
      if (completedSimulations.length < levelSettings.value) {
        enabledSimulation = false;
        break;
      }
    }
    return enabledSimulation;
  }
}
