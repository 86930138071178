import { Pipe, PipeTransform } from '@angular/core';
import { ModuleEnum } from '../_utils/unums/module.enum';
import { SIMULATION_PROGRESS_SETTINGS } from '../_utils/settings/simulation-progress.settings';
import { UserGradeEnum } from '../_utils/enums/user-grade.enum';
import { UserJourneyService } from '../_services/user-journey.service';
import { GenericFunctions } from '../_utils/generic.functions';

@Pipe({
  name: 'avatarSimulationProgress'
})
export class AvatarSimulationProgressPipe implements PipeTransform {

  constructor(private userJourneyService: UserJourneyService) {
  }

  async transform(simulations: any[], module: ModuleEnum, simulationProgressSettings?: any): Promise<any> {
    if (!module) {
      return;
    }
    const userGrade: UserGradeEnum = await this.userJourneyService.getUserGrade(module);
    if (GenericFunctions.isNullOrUndefined(userGrade)) {
      return;
    }
    const settings = simulationProgressSettings || SIMULATION_PROGRESS_SETTINGS;
    const userSettings = settings.avatar.find(x => x.userGrade === userGrade);
    const levels = {};
    for (const levelSettings of userSettings.config) {
      const completedSimulations = simulations.filter(x => x.completed && x.level === levelSettings.level).length;
      const completed = completedSimulations > levelSettings.value ? levelSettings.value : completedSimulations;
      const required = levelSettings.value;
      levels[levelSettings.level] = {
        level: levelSettings.level,
        required,
        completed,
        label: completed < required ? `${completed}/${required}` : `${required}/${required}`,
        percentage: Math.round((completed < required ? completed / required : required / required) * 100)
      };
    }
    const auxLevels = Object.keys(levels).map(level => levels[level]).filter(x => !isNaN(x.percentage));
    const percent = Math.round(auxLevels.reduce((a, b: any) => a + b.percentage, 0) / auxLevels.length);
    return {
      levels,
      percent,
    };
  }

}
