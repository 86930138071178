import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firebaseService: FirebaseService,
  ) {
  }

  public getUser = async (uid: string): Promise<any> => await this.firebaseService.getDocument(`users/${uid}`);
  public getUserProperty = async (uid: string, path: string): Promise<any> => await this.firebaseService.getDocument(`users/${uid}/${path}`);

}
