import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../../_services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TutorialService } from 'src/app/_services/tutorial.service';
import { ComponentBase } from '../../_utils/component.base';
import { EventsService } from '../../_services/events.service';
import { UserRedirectGuard } from '../../_guards/user-redirect.guard';

@Component({
  selector: 'app-guided-visit-modal',
  templateUrl: './guided-visit-modal.component.html',
  styleUrls: ['./guided-visit-modal.component.scss'],
})
export class GuidedVisitModalComponent extends ComponentBase implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() secondStepDescription: string;
  @Input() buttonText: string;
  @Input() nextStepUrl: string;
  @Input() lastStep: boolean;
  @Input() isSamllWindow: boolean = false;
  @Input() lastDemoModal: boolean = false;
  @Input() expiredDemoModal: boolean = false;
  @Input() firstVGStep: boolean = false;
  @Input() payload: any;

  step = 0;
  hasSimId = false;

  user;
  isDemoUser = false;

  infoLoaded = false;

  constructor(private modalCtrl: ModalController,
    private authService: AuthService,
    public translate: TranslateService,
    private tutorialService: TutorialService,
    private eventService: EventsService,
    private userRedirectGuard: UserRedirectGuard) {
    super();
  }

  ngOnInit() {
    this.translations()

    this.storage.get('user_journey').then(res => {
      if (res && res.demo) {
        this.isDemoUser = true;
      }
    })

    this.authService.getUser().then(res => {
      this.user = res;
      this.infoLoaded = true
    })
    this.storage.get('sim_id').then(res => {
      if (res) this.hasSimId = true;
    })
  }

  async next(): Promise<void> {
    if (this.lastStep) {
      this.step = 1;
    } else {
      await this.userJourneyService.updateJourneyStatus('lockedVG');
      this.modalCtrl.dismiss();
      const url = '/' + this.nextStepUrl;
      if(this.router.url === url) {
        this.tutorialService.urlHandler(url);
      } else {
        await this.router.navigateByUrl(this.nextStepUrl);
        this.eventService.setEvent('open-menu-module');
        this.eventService.setEvent('open-menu', true);
      }
    }
  }

  sendEmail() {
    window.open('mailto:support@simskills.io', '_blank');
  }

  protected async start(): Promise<void> {
    this.eventService.setEvent('open-menu', false);
    this.tutorialService.isGuidedVisit = false;
    await this.storage.set('guided_visit', false);
    if (this.isDemoUser) {
      this.firebaseService.updateDocument(`/user_journey/${this.user.companyLowercase}`, this.user.uid, { guided_visit: true, journey: '/simulations-list' });
      const userJourney = await this.storage.get('user_journey');
      this.router.navigateByUrl(`/${userJourney.current_module}/simulations-list`);
    } else {
      this.firebaseService.updateDocument(`/user_journey/${this.user.companyLowercase}`, this.user.uid, { guided_visit: true });
      this.userRedirectGuard.isUserRedirected = false;
      this.router.navigateByUrl('/dashboard');
    }

    this.modalCtrl.dismiss();
    this.eventService.setEvent('open-menu-module');
  }

  logout() {
    this.modalCtrl.dismiss();
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  translations() {
    if (this.description) this.description = this.translate.instant(this.description);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
