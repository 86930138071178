import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Storage } from '@ionic/storage';
import { ModuleEnum } from '../_utils/unums/module.enum';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  constructor(
    private firebaseService: FirebaseService,
    private storage: Storage,
  ) {
  }

  public async hasPreTraining(module: ModuleEnum): Promise<boolean> {
    const user = await this.storage.get('user');
    const assessment = await this.firebaseService.getDocument(`user_assessments/${user.uid}/${module}/pre_training`);
    return !!assessment;
  }
}
