import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ComponentBase } from '../_utils/component.base';
import { UserScheduledSimulationsService } from '../_services/user-scheduled-simulations.service';

const EXCLUDE_PATHS: string[] = [
  'login',
  'folder/:id',
];

@Injectable({
  providedIn: 'root'
})
export class UserRedirectGuard extends ComponentBase implements CanActivateChild {
  public isUserRedirected: boolean;

  constructor(
    private userScheduledSimulationsService: UserScheduledSimulationsService
  ) {
    super();
  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // return true;
    if (this.isUserRedirected) {
      return true;
    }
    // MAINTENANCE PAGE //
    // redirect all users when the platform should be shut down
    ////////////////////////////////////////////////////////////
    // this.isUserRedirected = true;
    // await this.router.navigateByUrl('maintenance');
    // return true;
    ////////////////////////////////////////////////////////////
    const userJourney = await this.storage.get('user_journey');
    const isCompleteGuidedVisit = await this.userJourneyService.getUserJourney('guided_visit');
    if(!isCompleteGuidedVisit && userJourney?.journey?.status === 'lockedVG') {
      return true;
    }
    await this.userJourneyService.unlockStatusVG(userJourney);
    this.isUserRedirected = true;
    if (!userJourney && EXCLUDE_PATHS.some(x => x === childRoute.routeConfig.path)) {
      return true;
    }
    if (childRoute.queryParams?.source) {
      return true;
    }
    if (!userJourney?.journey?.url) {
      await this.router.navigateByUrl('/dashboard');
      return false;
    }
    if (userJourney?.journey?.status === 'unlocked') {
      const simulationPending = await this.userScheduledSimulationsService.userPendingScheduleToday();
      if (simulationPending) {
        await this.navigateAndSaveUserJourneyP2P([simulationPending.module, 'simulations-peers']);
        return false;
      }
    }
    else if (userJourney?.journey?.status === 'lockedP2P') {
      const simulationPending = await this.userScheduledSimulationsService.userPendingScheduleToday();
      if (!simulationPending) {
        let module = userJourney.journey?.url.split('/')[1];
        await this.userJourneyService.updateUserJourney('journey', {url: `/${module}/simulations-peers`, status: 'unlocked'})
        await this.navigateAndSaveUserJourney([module, 'simulations-peers']);
        return false;
      }
    }

    if (childRoute['_routerState'].url.split('?')[0] != userJourney.journey?.url) {
      await this.router.navigateByUrl(userJourney.journey?.url);
      return false;
    }

    return true;
  }

}
