import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { GuidedVisitModalComponent } from '../guided-visit-modal/guided-visit-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UserRedirectGuard } from '../../_guards/user-redirect.guard';
import { ComponentBase } from '../../_utils/component.base';


@Component({
  selector: 'app-guided-visit-popover',
  templateUrl: './guided-visit-popover.component.html',
  styleUrls: ['./guided-visit-popover.component.scss'],
})
export class GuidedVisitPopoverComponent extends ComponentBase implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() nextStepUrl: string;
  @Input() buttonTextSecondary: string = '';
  @Input() prevStepUrl: string;
  @Input() buttonTextLink: string;
  @Input() skipUrl: string;
  @Input() lastStep: boolean = false;

  hasSimId = false;

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    public translate: TranslateService,
    private userRedirectGuard: UserRedirectGuard) {
    super();
  }

  ngOnInit() {
    this.translations();
    this.storage.get('sim_id').then(res => {
      if (res) this.hasSimId = true;
    })
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.next()
    }
  }

  next() {
    if (this.lastStep) {
      let props = {}
      if(this.hasSimId) {
        props = {
          title: 'Additional information',
          buttonText: 'Next',
          description: 'To begin, we conduct an <b>initial assessment<b> to evaluate skills, and throughout the training, we <b>keep track<b> of the progress.',
          lastStep: true,
        }
      } else {
        props = {
          lastStep: true,
        }
      }
      this.openLastModal(props);
    } else {
      this.router.navigateByUrl(this.nextStepUrl);
    }

    this.popoverCtrl.dismiss();
  }

  prev() {
    this.router.navigateByUrl(this.prevStepUrl);
    this.popoverCtrl.dismiss();
  }

  skip() {
    this.router.navigateByUrl(this.skipUrl);
    this.popoverCtrl.dismiss();
  }

  async openLastModal(props) {
    const userJourney = await this.storage.get('user_journey');
    await this.userJourneyService.unlockStatusVG(userJourney);
    await this.router.navigateByUrl(userJourney.journey?.url);
    const modal = await this.modalCtrl.create({
      component: GuidedVisitModalComponent,
      backdropDismiss: false,
      cssClass: 'last-guide-modal',
      componentProps: props
    });

    return await modal.present();
  }

  translations() {
    this.description = this.translate.instant(this.description);
  }

}

