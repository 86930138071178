import { EventEmitter, Injectable } from '@angular/core';
import { MenuController, ModalController, PopoverController } from '@ionic/angular';
import { GuidedVisitModalComponent } from '../_components/guided-visit-modal/guided-visit-modal.component';
import { Storage } from '@ionic/storage-angular';
import { GUIDE_VISIT_SETTINGS } from '../_utils/settings/guide-visit.settings'
import { GuidedVisitPopoverComponent } from '../_components/guided-visit-popover/guided-visit-popover.component';
import { GenericFunctions } from '../_utils/generic.functions';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  isGuidedVisit;
  hasSimId;

  dashboardModal;
  feedbackIntroductionPopover;
  learningPopover;
  avatarSimulationsPopover;
  peersSimulationsPopover;

  modalSamllWindow;
  modalSamllWindowNoVG;
  public omitResponsiveFlow$: EventEmitter<boolean>;

  constructor(private modalCtrl: ModalController, private storage: Storage, private popoverCtrl: PopoverController,
              private menuController: MenuController, private eventService: EventsService) {
    this.initVariables();
    window.addEventListener('resize', async () => {
      if (this.isGuidedVisit) {
        this.eventService.setEvent('open-menu', true);
      }
    });
  }

  async initGuideVisitHandler() {
    this.storage.get('sim_id').then(res => {
      if (res) this.hasSimId = true;
    });
  }

  async urlHandler(url): Promise<void> {
    switch (url) {
      case '/':
        await this.openGuideVisitModal(GUIDE_VISIT_SETTINGS.dashboardModal);
        break;
      case '/dashboard':
        await this.openGuideVisitModal(GUIDE_VISIT_SETTINGS.dashboardModal);
        break;
      case '/feedback/intro':
        await this.menuController.enable(true, 'main');
        await GenericFunctions.awaitTime(100);
        await this.openGuideVisitPopover(GUIDE_VISIT_SETTINGS.feedbackIntroductionPopover);
        break;
      case '/feedback/learning':
        await this.openGuideVisitPopover(GUIDE_VISIT_SETTINGS.learningPopover);
        break;
      case '/feedback/simulations':
        if (this.hasSimId) {
          await this.openGuideVisitPopover(GUIDE_VISIT_SETTINGS.avatarSimulationsIdPopover);
        } else {
          await this.openGuideVisitPopover(GUIDE_VISIT_SETTINGS.avatarSimulationsPopover);
        }
        break;
      case '/feedback/simulations-peers':
        await this.openGuideVisitPopover(GUIDE_VISIT_SETTINGS.peersSimulationsPopover);
        break;
      default:
        break;
    }
  }

  async openGuideVisitModal(settings) {
    if (this[settings.key]) {
      return;
    }
    this[settings.key] = await this.modalCtrl.create({
      component: GuidedVisitModalComponent,
      ...settings.modalOptions
    });
    this[settings.key].present();
    await this[settings.key].onWillDismiss();
    this[settings.key] = null;
  }

  async openGuideVisitPopover(settings) {
    this[settings.key] = await this.popoverCtrl.create({
      component: GuidedVisitPopoverComponent,
      ...settings.popoverOptions,
      side: window.innerWidth <= 600? 'bottom': settings.popoverOptions.side,
    });

    await this[settings.key].present();
  }

  public async isVGFlow(): Promise<boolean> {
    const userJourney = await this.storage.get('user_journey');
    return userJourney?.journey?.status === 'lockedVG';
  }

  private initVariables(): void {
    this.omitResponsiveFlow$ = new EventEmitter();
  }
}
