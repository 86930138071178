import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ComponentBase } from '../_utils/component.base';
import { TutorialService } from '../_services/tutorial.service';
import { UserGradeService } from '../_services/user-grade.service';

@Injectable({
  providedIn: 'root'
})
export class CompleteModuleGuard extends ComponentBase implements CanActivate {
  constructor(
    private tutorialService: TutorialService,
    private userGradeService: UserGradeService
  ) {
    super();
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!route.params.module || await this.tutorialService.isVGFlow()) {
      return true;
    }
    const isDemo = await this.userJourneyService.isDemo();
    if (isDemo) {
      return true;
    }
    const hasUserGrade = await this.userGradeService.hasUserGrade(route.params.module);
    if(!hasUserGrade) {
      return true;
    }
    const moduleStatus = await this.userJourneyService.getCompletedModuleStatus(route.params.module);
    if (moduleStatus === 'complete') {
      await this.navigateAndSaveUserJourney([route.params.module, 'greeting']);
      return false;
    }
    return true;
  }
}
