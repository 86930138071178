export const GUIDE_VISIT_SETTINGS = {
    dashboardModal: {
        key: 'dashboardModal',
        modalOptions: {
            backdropDismiss: false,
            cssClass: 'guide-modal',
            componentProps: {
                nextStepUrl: 'feedback/intro',
                buttonText: 'Start',
                firstVGStep: true
            }
        }
    },
    feedbackIntroductionPopover: {
        key: 'feedbackIntroductionPopover',
        popoverOptions: {
            cssClass: 'guided-visit-popover',
            backdropDismiss: false,
            mode: 'ios',
            side: 'right',
            alignment: 'center',
            arrow: true,
            trigger: 'feedback-intro-popover',
            componentProps: {
                title: 'Introduction',
                description: 'In this section, you\'ll discover short training capsules created by experts in coaching and psychology.',
                buttonText: 'Next',
                nextStepUrl: 'feedback/learning',
            }
        }
    },
    learningPopover: {
        key: 'learningPopover',
        popoverOptions: {
            cssClass: 'guided-visit-popover',
            backdropDismiss: false,
            mode: 'ios',
            side: 'right',
            alignment: 'center',
            arrow: true,
            trigger: 'learning-intro-popover',
            componentProps: {
                title: 'Best Practices',
                description: 'Next, we introduce a practical methodology and showcase real case videos to enhance learning through observation.',
                buttonText: 'Next',
                nextStepUrl: 'feedback/simulations',
                buttonTextLink: 'Previous',
                prevStepUrl: 'feedback/intro'
            }
        }
    },
    avatarSimulationsPopover: {
        key: 'avatarSimulationsPopover',
        popoverOptions: {
            cssClass: 'guided-visit-popover',
            backdropDismiss: false,
            mode: 'ios',
            side: 'right',
            alignment: 'center',
            arrow: true,
            trigger: 'simulations-popover',
            componentProps: {
                title: 'Avatar simulations',
                description: 'The next section is your practice space in a safe and anonymous environment.<br><br> Here you will do one-on-one training with the avatar, where you will learn how to handle yourself in different work contexts and receive real-time feedback to constantly improve.',
                buttonText: 'Next',
                nextStepUrl: 'feedback/simulations-peers',
                buttonTextLink: 'Previous',
                prevStepUrl: 'feedback/learning',
            }

        }
    },
    avatarSimulationsIdPopover: {
        key: 'avatarSimulationsPopover',
        popoverOptions: {
            cssClass: 'guided-visit-popover',
            backdropDismiss: false,
            mode: 'ios',
            side: 'right',
            alignment: 'center',
            arrow: true,
            trigger: 'simulations-popover',
            componentProps: {
                title: 'Avatar simulations',
                description: 'The next section is your practice space in a safe and anonymous environment.<br><br> Here you will do one-on-one training with the avatar, where you will learn how to handle yourself in different work contexts and receive real-time feedback to constantly improve.',
                buttonText: 'Practice',
                nextStepUrl: 'feedback/simulaions-list',
                buttonTextLink: 'Previous',
                prevStepUrl: 'feedback/learning',
            }

        }
    },
    peersSimulationsPopover: {
        key: 'peersSimulationsPopover',
        popoverOptions: {
            cssClass: 'guided-visit-popover',
            backdropDismiss: false,
            mode: 'ios',
            side: 'right',
            alignment: 'center',
            arrow: true,
            trigger: 'simulations-peers-popover',
            componentProps: {
                title: 'Peers simulations',
                description: 'Lastly, we have the space for simulations between two people. <br><br> What do they consist of? <br> You will have the opportunity to engage in role-play simulations with other individuals in the company, which will aid in reinforcing the knowledge you\'ve acquired.',
                buttonText: 'Next',
                nextStepUrl: 'feedback/simulations-peers',
                buttonTextLink: 'Previous',
                prevStepUrl: 'feedback/simulations',
                lastStep: true,
            }

        }
    },
    modalSamllWindow: {
        key: 'modalSamllWindow',
        modalOptions: {
            backdropDismiss: false,
            cssClass: 'guide-modal',
            componentProps: {
                description: 'In order to be able to make this guided tour, the screen must be larger.',
                isSamllWindow: true
            }

        }
    },
    modalSamllWindowNoVG: {
        key: 'modalSamllWindowNoVG',
        modalOptions: {
            backdropDismiss: false,
            cssClass: 'guide-modal',
            componentProps: {
                description: 'This page must be accessed from a computer. It is not available for mobile devices.',
                isSamllWindow: true
            }

        }
    }
}
