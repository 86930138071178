import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 50, isExpanded: boolean = false): string {
    if (!value) {
      return value;
    }
    const words = value?.split(' ');
    if (words.length > limit && !isExpanded) {
      return words.slice(0, limit).join(' ') + '... <span class="show-more" style="cursor: pointer; color: blue;">[show more]</span>';
    }
    return value;
  }
}
