import { HostListener, inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModuleEnum } from './unums/module.enum';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FirebaseService } from '../_services/firebase.service';
import { AlertController, LoadingController, MenuController, NavController } from '@ionic/angular';
import { UserJourneyService } from '../_services/user-journey.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationDirection, UserJourneyStatus } from './custom-types';
import { firstValueFrom } from 'rxjs';
import { HiddenModalTypesEnum } from './unums/hidden-modal-types.enum';
import { EventsService } from '../_services/events.service';
import { GenericFunctions } from './generic.functions';

@Injectable()
export class ComponentBase {
  public moduleEnum: typeof ModuleEnum;
  public modalTypeEnum: typeof HiddenModalTypesEnum
  public screenSize: number;
  public translateService: TranslateService;
  protected storage: Storage;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected firebaseService: FirebaseService;
  protected menuController: MenuController;
  protected userJourneyService: UserJourneyService;
  protected navController: NavController;
  protected loadingController: LoadingController;
  protected alertCtrl: AlertController;
  protected eventsService: EventsService;

  private navigateStrategy: any;

  constructor() {
    this.moduleEnum = ModuleEnum;
    this.modalTypeEnum = HiddenModalTypesEnum;
    this.storage = inject(Storage);
    this.screenSize = window.innerWidth;
    this.translateService = inject(TranslateService);
    this.router = inject(Router);
    this.activatedRoute = inject(ActivatedRoute);
    this.firebaseService = inject(FirebaseService);
    this.menuController = inject(MenuController);
    this.userJourneyService = inject(UserJourneyService);
    this.navController = inject(NavController);
    this.loadingController = inject(LoadingController);
    this.alertCtrl = inject(AlertController);
    this.eventsService = inject(EventsService);
    this.initNavigateStrategy();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenSize = window.innerWidth;
  }

  public async navigateAndSaveUserJourney(commands: string[], extras?: NavigationExtras, status?: UserJourneyStatus): Promise<void> {
    await this.navigate(commands, {...extras});
    await this.saveUserJourneyJourney(this.router.url, status);
  }

  protected async navigateAndSaveUserJourneyP2P(commands: string[], extras?: NavigationExtras): Promise<void> {
    await this.userJourneyService.updateJourneyStatus('unlocked');
    await this.navigateAndSaveUserJourney(commands, {...extras}, 'lockedP2P');
  }

  protected async navigate(commands: string[], extras?: NavigationExtras, navigationDirection: NavigationDirection = 'root'): Promise<boolean> {
    let result: boolean;
    if (!navigationDirection) {
      result = await this.navigateStrategy.root(commands, extras);
    } else {
      result = await this.navigateStrategy[navigationDirection](commands, extras);
    }
    const module = this.activatedRoute.snapshot.params?.module;
    if (module) {
      this.eventsService.setEvent('open-menu-module', module);
    }
    return result;
  }

  protected statusMenuHandler(status: boolean): void {
    this.menuController.enable(status, 'main').then();
  }

  protected async openSpinner(message = ''): Promise<HTMLIonLoadingElement> {
    const spinner = await this.loadingController.create({spinner: 'circles', message});
    await spinner.present();
    return spinner;
  }

  protected async saveUserJourneyJourney(url?: string, status: UserJourneyStatus = 'unlocked', includeParams: boolean = true): Promise<void> {
    const userJourneyStatus = await this.userJourneyService.getJourneyStatus();
    if (userJourneyStatus && userJourneyStatus !== 'unlocked') {
      return;
    }
    
    let auxUrl = url || this.router.url;
    if (!includeParams) auxUrl = auxUrl.split('?')[0];
    if (GenericFunctions.validateUrl(auxUrl, 'not-found')) {
      return;
    }
    await this.userJourneyService.updateUserJourney('journey', {url: auxUrl, status});
  }

  protected async getCurrentLang(): Promise<string> {
    let lang = this.translateService.currentLang;
    if (!lang) {
      lang = (await firstValueFrom(this.translateService.onLangChange))?.lang;
    }
    return lang;
  }

  protected isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  private initNavigateStrategy(): void {
    this.navigateStrategy = {
      root: async (commands: string[], extras?: NavigationExtras) => await this.navController.navigateRoot(commands, {...extras || {}}),
      back: async (commands: string[], extras?: NavigationExtras) => await this.navController.navigateBack(commands, {...extras || {}}),
      forward: async (commands: string[], extras?: NavigationExtras) => await this.navController.navigateForward(commands, {...extras || {}})
    };
  }
}
