import { Pipe, PipeTransform } from '@angular/core';
import { ModuleEnum } from '../_utils/unums/module.enum';
import { ModuleStatus } from '../_utils/custom-types';

@Pipe({
  name: 'enabledModule'
})
export class EnabledModulePipe implements PipeTransform {

  constructor() {
  }

  transform(status: ModuleStatus): boolean {
    return status === 'active';
  }

}
